import {Card, CardContent, Typography, Grid} from '@mui/material';
import './MetricCard.scss'
import NorthIcon from '@mui/icons-material/North';

const MetricCard = ({ title, value , porcent}) => {
  return (
    <Card>
      <CardContent>
				<Grid container >
					<Grid item xs={2}>
								<Typography className='metric-tittle'>{title}</Typography>
					</Grid>
					<Grid item xs={12}>		
						<Typography className='metric-value'>{value}</Typography>
					</Grid>
					<Grid item xs={1}>
						<NorthIcon className='metric-icon'	/>
					</Grid>
					<Grid item xs={1}>
					<Typography className='metric-porcent'>%{porcent}</Typography>
					</Grid>
				</Grid>
      </CardContent>
    </Card>
  );
};

export default MetricCard;