import React from "react";
import './styles/style.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {  
  Login, 
  Users, 
  Suscription, 
  Dashboard, 
  Customer , 
  Partner,
  Flows
} from './pages';
import { createTheme, ThemeProvider } from "@mui/material";
import { Root } from "./components";
import { Provider } from "react-redux";
import store from "../src/redux/store";

const theme = createTheme({
  palette: {
        type: 'light',
        primary: {
            main: '#FF0050' //pink
        },
        secondary: {
            main: '#0A0544'  //blue
        },
      },
      typography: {
          fontFamily: "Quicksand"
      }
});


function App() {
  return (
    <div className="app-container">
      <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Root>
            <Routes>
              <Route path="/"  element={<Login/>} />
              <Route path="/users" element={<Users/>} />
              <Route path="/partners" element={<Partner/>} />
              <Route path="/suscriptions" element={<Suscription/>} />
              <Route path="/home" element={<Dashboard/>} />
              <Route path="/customers" element={<Customer/>} />
              <Route path="/flows" element={<Flows/>} />
            </Routes>
          </Root>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
    </div>
  );
}

export default App;
