import  React, { useState } from "react";
import './CardSuscription.scss';
import {
	Tab, 
	Tabs,
	Button,
	AppBar,
	TextField,
	Dialog,
	Grid,
	IconButton,
	Icon,
	Typography,
	Box,
	FormControl,
	InputLabel,
	Divider,
	Avatar
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from "styled-components";
import { useDispatch } from 'react-redux';
import { 
	createUser,
	deleteUser, 
	updateUser
} from "../../redux/actions/user-actions";
import { getSuscriptions } from "../../redux/actions/suscription-actions";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import AvatarPerro from '../../assets/images/avatar-perro.png';
import AvatarGato from '../../assets/images/avatar-gato.png';

const CardSuscription = ({item, openModal, setOpenModal, callback, notification, cli}) => {
	const [open, setOpen] = useState(openModal);
	const dispatch = useDispatch()
	const userCreate = async (user) => dispatch(createUser(user))
	const userDelete = async (id) => dispatch(deleteUser(id))
	const userUpdate = async (id, user) => dispatch(updateUser(id, user))
	const getUserSuscriptions = async (id) => dispatch(getSuscriptions(id))
	const [valueTab, setValueTab] = useState(0);



	const validationSchema = Yup.object({
				type: Yup.string().required('Requerido'),
			  channel: Yup.string().required('Requerido'),
    })

	const formik = useFormik({
		initialValues: {
				name: item !== null ? item.name:'',
				channel: item !== null ? item.channel:'',
				cotizacion: item !== null ? item.id_cotization:'',
				payment_name: item !== null ? item.payment_name: '',
				customer: item !== null ? item.id_customer: '',
				amount: item !== null ? item.amount: '',
				date: item !== null ? item.created_at:'' ,
		},
		onSubmit:  async (values) => {
			if(item){
				await userUpdate(item._id,values)
				notification('Usuario actualizado correctamente!')
			}else{
				await userCreate(values)
				notification('Usuario creado correctamente !')
			}
			callback();
			handleClose();
		},
		validationSchema,
		validate: values => {
				
				}
		})


	const handleDelete = async() => {
		await userDelete(item._id);
		callback();
		notification('Usuario eliminado correctamente !')
		handleClose();
  };

	const handleClose = () => {
    setOpen(false);
		setOpenModal(false);
  };

	const handleChangeTab =async  (event, newValue) => {
		setValueTab(newValue);
		if(newValue === 2)
		{
			await getUserSuscriptions(item?.id)
		}
	};

	function TabPanel(props) {
			const { children, value, index, ...other } = props;
			return (
			<Grid
					role="tabpanel"
					hidden={value !== index}
					id={`simple-tabpanel-${index}`}
					aria-labelledby={`simple-tab-${index}`}
					{...other}
			>
							{value === index && (
							<Box p={3} >
									<Typography  component="div">{children}</Typography>
							</Box>
							)}
			</Grid>
			);
		}

  return (
		<Dialog
		open={open}
		onClose={setOpenModal}
		aria-labelledby="alert-dialog-title"	
		aria-describedby="alert-dialog-description"
		align='center'
		maxWidth="lg"
	>
		<form onSubmit={formik.handleSubmit} style={{padding: '0px'}}>
    <Grid 	className="modal-container">
				<Grid container justify="flex-end" className="card-close">
					<Grid item xs={10}></Grid>
					<Grid item xs={2} align='right'>
						<CloseButton onClick={handleClose}>
									<CloseIcon className="fas fa-times" />
							</CloseButton>
					</Grid>
				</Grid>

				<Typography variant="h5" className="mb-2">Póliza <b>{item.policy_number}</b></Typography>

				{/* Tabs de la informacion del Proveedor */}
				<AppBar position="static">
        <Tabs 
						style={{backgroundColor:"rgb(258,236,225)"}} 
						value={valueTab} onChange={handleChangeTab} 
						aria-label="simple tabs example"
					>
            <Tab label="Datos póliza"  />
            <Tab label={ item?.pet ? "Mascota":"Beneficiarios" }  />
        </Tabs>
        </AppBar>

				{/* Datos Póliza */}
				<TabPanel value={valueTab} index={0}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								label="Nombre Plan"
								id="outlined-size-small"
								name='name'
								fullWidth
								className="text-import mt-1"
								value={formik.values.name} 	
								error={formik.touched.name && formik.errors.name ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.name && formik.errors.name ? formik.errors.name	: ''}
								InputProps={{
									readOnly: true,
									style: {fontWeight: 600} 
								}}
								/>
						</Grid>

						<Grid item xs={6}>
							<TextField
								label="Canal"
								id="outlined-size-small"
								name='channel'
								fullWidth
								className="text-import mt-1"
								value={formik.values.channel} 	
								error={formik.touched.channel && formik.errors.channel ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.channel && formik.errors.channel ? formik.errors.channel	: ''}
								InputProps={{
									readOnly: true,
									style: {color: '#FF0050'} 
								}}
								/>
						</Grid>

						<Grid item xs={6}>
							<TextField
								label="Cotización"
								id="outlined-size-small"
								name='cotizacion'
								fullWidth
								className="text-import mt-1"
								value={formik.values.cotizacion} 	
								error={formik.touched.cotizacion && formik.errors.cotizacion ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.cotizacion && formik.errors.cotizacion ? formik.errors.cotizacion	: ''}
								InputProps={{
									readOnly: true,
								}}
								/>
						</Grid>

						<Grid item xs={6}>
							<TextField
								label="Nombre pagador"
								id="outlined-size-small"
								name='payment_name'
								fullWidth
								className="text-import mt-1"
								value={formik.values.payment_name} 	
								error={formik.touched.payment_name && formik.errors.payment_name ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.payment_name && formik.errors.payment_name ? formik.errors.payment_name	: ''}
								InputProps={{
									readOnly: true,
								}}
								/>
						</Grid>

						<Grid item xs={6}>
							<TextField
								label="Id Cliente"
								id="outlined-size-small"
								name='cutomer'
								fullWidth
								className="text-import mt-1"
								value={formik.values.customer} 	
								error={formik.touched.customer && formik.errors.customer ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.customer && formik.errors.customer ? formik.errors.customer	: ''}
								InputProps={{
									readOnly: true,
								}}
								/>
						</Grid>

						<Grid item xs={6}>
								<FormControl fullWidth className=" mt-1" >
								<InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
								<OutlinedInput
									id="outlined-adornment-amount"
									startAdornment={<InputAdornment position="start">$</InputAdornment>}
									label="Amount"
									value={formik.values.amount} 	
									InputProps={{	
										readOnly: true,
									}}
								/>
							</FormControl>
						</Grid>
						

						<Grid item xs={6}>
							<TextField
								label="Fecha"
								id="outlined-size-small"
								name='date'
								fullWidth
								className="text-import  mt-1"
								value={formik.values.date} 	
								error={formik.touched.date && formik.errors.date ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.date && formik.errors.date ? formik.errors.date	: ''}
								InputProps={{
									readOnly: true,
								}}
								/>
						</Grid>
						
						<Grid item xs={3}>
							<Button
								label="Fecha"
								id="outlined-size-small"
								variant="contained" 
								fullWidth
								className=" mt-1"
								InputProps={{
									readOnly: true,
								}}
								disabled
								>
									Descargar póliza
								</Button>
						</Grid>

						<Grid item xs={6}></Grid>

						<Grid item xs={3}>
							<Button
								label="Fecha"
								id="outlined-size-small"
								variant="contained" 
								fullWidth
								color="secondary"
								className=" mt-1"
								InputProps={{
									readOnly: true,
								}}
								>
									Ver cliente
								</Button>
						</Grid>
					</Grid>
				</TabPanel>

				{/* Beneficiarios o mascota */}
				<TabPanel value={valueTab} index={1}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
						{
								item?.pet ? (
									<Grid container  className="mt-1"> 
									<Grid item xs={6}>
										<Grid item xs={12} className="mt-2">
													<Avatar 
													alt="avatar-dog"
													src={item?.pet.species === "Perro" ? AvatarPerro:AvatarGato}  
													sx={{width: 200, height: 200}}
													style={{backgroundColor: '#FF0050' }}
												/>
												</Grid>	
										</Grid>	
										<Grid item xs={6}>
											<Grid container spacing={2}>
													<Grid item xs={12}>
															<TextField
																label="Nombre"
																id="outlined-size-small"
																name='name'
																fullWidth
																value={item.pet.name} 	
																InputProps={{
																	readOnly: true,
																}}
																/>
														</Grid>	
														<Grid item xs={6} className="mt-1">
															<TextField
																label="Genero"
																id="outlined-size-small"
																name='gender'
																fullWidth
																value={item.pet.gender} 	
																InputProps={{
																	readOnly: true,
																}}
																/>
														</Grid>	
														<Grid item xs={6} className="mt-1">
															<TextField
																label="Especie"
																id="outlined-size-small"
																name='species'
																fullWidth
																value={item.pet.species} 	
																InputProps={{
																	readOnly: true,
																}}
																/>
														</Grid>	
														<Grid item xs={6} className="mt-1">
															<TextField
																label="Edad"
																id="outlined-size-small"
																name='aage'
																fullWidth
																value={item.pet.age} 	
																InputProps={{
																	readOnly: true,
																}}
																/>
														</Grid>	
											</Grid>
											</Grid>	
										</Grid>
								):(
									<Grid>
									{
										item?.beneficiaries.map((ben, index)=>(
											<Grid container spacing={2} className="mt-1">
												<Grid item xs={12} align='left'>
												<Typography  style={{fontWeight: 600}}>Beneficiario {index+1}</Typography>
												</Grid>
												<Grid item xs={4}>
														<TextField
															label="Canal"
															id="outlined-size-small"
															name='channel'
															fullWidth
															value={ben.kinship} 	
															InputProps={{
																readOnly: true,
															}}
															/>
												</Grid>
												<Grid item xs={4}>
													<TextField
														label="RUT"
														id="outlined-size-small"
														name='RUT'
														fullWidth
														value={ben.RUT} 	
														InputProps={{
															readOnly: true,
														}}
														/>
												</Grid>
												<Grid item xs={4}>
													<TextField
														label="Fecha nacimiento"
														id="outlined-size-small"
														name='birthdate'
														fullWidth
														value={ben.birthdate} 	
														InputProps={{
															readOnly: true,
														}}
														/>
												</Grid>
											<Divider />
										</Grid>
										))
									}
									</Grid>
								)
						}
					
						</Grid>
						{
							!item?.pet && (
								<Grid item xs={4}>
								<Button
									label="Fecha"
									id="outlined-size-small"
									variant="contained" 
									fullWidth
									color="secondary"
									className=" mt-1"
									InputProps={{
										readOnly: true,
									}}
									disabled
									>
										Agregar Beneficiario
									</Button>
								</Grid>
							)
						}
						
					</Grid>
				</TabPanel>

    </Grid>
		</form>
		<Grid container spacing={2} >
				{
						item && (
							<Grid xs={6} >
									<Button 
									disabled={true}
									variant="contained" 
									color="secondary"
									onClick={handleDelete}
									>
										ELIMINAR
								</Button>
							</Grid>
						)
					}
					<Grid  xs={6}>
									<Button 
									disabled={true}
									variant="contained" 
									color="primary" 
									type="submit"
									>
										GUARDAR
								</Button>
					</Grid>
				</Grid>
		</Dialog>
  );
};

export default CardSuscription	;
 
const CloseButton = styled(IconButton)`
    color: #FF0050 !important;
    width: 35px;
    height: 35px;
		margin: 5px;
`;

const CloseIcon = styled(Icon)`
    font-size: 1.3rem !important;
`;