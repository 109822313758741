import React, { useState } from 'react';
import {Paper} from '@mui/material';
import {
    PagingState,
    DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { alpha, styled } from '@mui/material/styles';
import CardSuscription from '../CardSuscription/CardSuscription';
import './Tables.scss';

const CurrencyFormatter = ({ value }) => (
    <b style={{ color: 'blue' }}>
        {value?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
    </b>
); 


const CurrencyTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={CurrencyFormatter}
        {...props}
    />
); 


const classes = {
    tableStriped: `ticker-tableStriped`,
};

const StyledTable = styled(Table.Table)(({ theme }) => ({
    [`&.${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
        },
    },
    }));

const TableComponent = props => (
    <StyledTable
        {...props}
        className={classes.tableStriped}
        />
    );

function TableSuscription({data , notification}){

    const [columns] = useState([
        { name: 'name', title: 'Plan' },
        { name: 'channel', title: 'Canal' },   
        { name: 'policy_number', title: 'Poliza'},
        { name: 'payment_name', title: 'Nombre Pago'},
        { name: 'amount', title: 'Precio'},
        { name: 'id_cotization', title: 'Cotizacion'},
    ]);

  const [tableColumnExtensions] = useState([
        { columnName: 'name', width: 350 },
    ]); 

    const [currencyColumns] = useState(['amount']);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(10);
    const [openDetail , setOpenDetail]= useState(false);
    const [clickedSuscription, setClickedSuscription] = useState(null);


	

    const TableRow = ({ row, ...restProps }) => (
        <Table.Row
            {...restProps}
                onDoubleClick={()=>{
                    handleItemClick(row)
                    setOpenDetail(true)
                }}
        />
    ); 

		const handleItemClick=(row)=>{
			setClickedSuscription(row)
		}


    return (
        <Paper style={{ position: 'relative' }}>
            <Grid
            rows={data}
            columns={columns}
            >
            <CurrencyTypeProvider
                for={currencyColumns}
            />
            <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
            />
            <Table
                tableComponent={TableComponent} 
                columnExtensions={tableColumnExtensions} 
                rowComponent={TableRow}
            />
            <TableHeaderRow />
            <PagingPanel />

            {
            openDetail && (
            <CardSuscription
                item={clickedSuscription}
                openModal={openDetail}
                setOpenModal= {setOpenDetail}
                notification={notification}
            />)
			}

            </Grid>
        </Paper>
    );
}

export default TableSuscription;