/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { updateUserRedux } from '../redux/actions/user-actions';

export default function Root({ children }) {

	let navegate = useNavigate();
	const dispatch = useDispatch();
	const updateUserLogin = async (user) => dispatch(updateUserRedux(user))

	const checkUser=async()=>{
		const storedUser = localStorage.getItem('userLogin');
		if(storedUser){
				updateUserLogin(JSON.parse(storedUser));
		}else{
				navegate('/')
		}
	}


	useEffect(()=>{
		checkUser();
	},[])


	return children
}