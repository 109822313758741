import React from "react";
import {  Grid } from "@mui/material";
import  Navbar  from '../../components/Navbar/Navbar';
import './Dashboard.scss'
import { MetricCard, MetricChart, MetricChartFee , MetricChartPie} from "../../components";


const Dashboard=()=>{
	return(
		<Grid className='main-container mt-4'>
			<Navbar />
			<Grid className="container-page">
					<Grid container spacing={3}>
						<Grid item xs={2.4}>
								<MetricCard title="Pólizas" value={150} porcent={10}/>
						</Grid>
						<Grid item xs={2.4}>
							<MetricCard title="Asegurados" value={300} porcent={5}/>
						</Grid>
						<Grid item xs={2.4}>
							<MetricCard title="Vendido" value="$5,000" porcent={5}/>
						</Grid>
						<Grid item xs={2.4}>
							<MetricCard title="Siniestros" value={2} porcent={3}/>
						</Grid>
						<Grid item xs={2.4}>
							<MetricCard title="partners" value={10} porcent={20}/>
						</Grid>
						<Grid item xs={6}>
								<MetricChart />
						</Grid>
						<Grid item xs={6}>
								<MetricChartFee />
						</Grid>
						<Grid item xs={6}>
								<MetricChartPie />
						</Grid>
				</Grid>
				</Grid>
		</Grid>
	)
}

export default Dashboard;