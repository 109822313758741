/* eslint-disable react-hooks/exhaustive-deps */
import {Fragment, useEffect, useState} from 'react';
import "./Navbar.scss";
import {
    AppBar,
    Toolbar,
    Icon,
    Grid,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Drawer,
    Avatar,
    Badge
    }  from '@mui/material'
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector } from "react-redux";
import { backend } from '../../utils';


function Navbar(){
    const history = useNavigate();
    const user = useSelector((state) => state.User);
    const [menu , setMenu]= useState([]);

    const menuAdmin =[
        {
            label:"Dashboard",
            path:"/home",
            iconColor: "secondary",
            icon: "fas fa-chart-bar",
            onClick: () => history("/home"),
        },
        {
            label:"Flujos",
            path:"/flows",
            iconColor: "secondary",
            icon: "fas fa-code-branch",
            onClick: () => history("/flows"),
        },
        {
            label:"Suscripciones",
            path:"/suscriptions",
            iconColor: "secondary",
            icon: "fas fa-file",
            onClick: () => history("/suscriptions"),
        },
        {
            label:"Asegurados",
            path:"/customers",
            iconColor: "secondary",
            icon: "fas fa-user",
            onClick: () => history("/customers"),
        },
        {
            label:"Partners",
            path:"/partners",
            iconColor: "secondary",
            icon: "fas fa-user-circle",
            onClick: () => history("/partners"),
        },  
        {
            label:"Usuarios",
            path:"/users",
            iconColor: "secondary",
            icon: "fas fa-lock",
            onClick: () => history("/users"),
        },
        {
            
            label:"Salir",
            path:"/",
            iconColor: "primary",
            icon: "fas fa-sign-out-alt",
            onClick: () => {loginOut()},
        },
    ]

    const menuInsurance =[
        {
            label:"Dashboard",
            path:"/home",
            iconColor: "secondary",
            icon: "fas fa-chart-bar",
            onClick: () => history("/home"),
        },
        {
            label:"Flujos",
            path:"/flows",
            iconColor: "secondary",
            icon: "fas fa-code-branch",
            onClick: () => history("/flows"),
        },
        {
            label:"Suscripciones",
            path:"/suscriptions",
            iconColor: "secondary",
            icon: "fas fa-file",
            onClick: () => history("/suscriptions"),
        },
        {
            label:"Asegurados",
            path:"/customers",
            iconColor: "secondary",
            icon: "fas fa-user",
            onClick: () => history("/customers"),
        },
        {
            label:"Partners",
            path:"/partners  ",
            iconColor: "secondary",
            icon: "fas fa-user-circle",
            onClick: () => history("/partners"),
        },
        {
            
            label:"Salir",
            path:"/",
            iconColor: "primary",
            icon: "fas fa-sign-out-alt",
            onClick: () => {loginOut()},
        },
    ]

    const menuPartner =[
        {
            label:"Dashboard",
            path:"/home",
            iconColor: "secondary",
            icon: "fas fa-chart-bar",
            onClick: () => history("/home"),
        },
        {
            label:"Suscripciones",
            path:"/suscriptions",
            iconColor: "secondary",
            icon: "fas fa-file",
            onClick: () => history("/suscriptions"),
        },
        {
            label:"Asegurados",
            path:"/customers",
            iconColor: "secondary",
            icon: "fas fa-user",
            onClick: () => history("/customers"),
        },
        {
            
            label:"Salir",
            path:"/",
            iconColor: "primary",
            icon: "fas fa-sign-out-alt",
            onClick: () => {loginOut()},
        },
    ]


    useEffect(()=>{
        const storedUser = JSON.parse(localStorage.getItem('userLogin'));
		if(storedUser){
            switch(storedUser?.rol){
                case backend.rol.admin:
                    setMenu(menuAdmin);
                    break;
                case backend.rol.insurance:
                    setMenu(menuInsurance);
                    break;
                case backend.rol.partner:
                        setMenu(menuPartner);
                        break;
                default:
                    break;
            }
		}else{
            history('/')
		}
    },[])

    const loginOut=()=>{
        localStorage.clear()
        history("/")
    }

    return(
    <Fragment>
        <Grid style={{display:'flex'}}>
        <AppBar position="fixed">
            <Toolbar className='toolbar'>
                <img src='https://embedx.io/blog/content/images/2022/09/EmbedX2@3x_3-2.png'  alt="logo" width={150}/>
                <Grid container direction="column" alignItems="flex-end">
                        <Grid item>
                            <Badge color="success" overlap="circular" badgeContent=" " variant="dot" >
                                <Avatar> {user?.user[0]}</Avatar>
                            </Badge>
                        </Grid>
                       {/*  <Grid item>
                            <Typography>{user?.user}</Typography>
                            <Typography variant='caption'>{user?.rol}</Typography>
                        </Grid> */}
                </Grid>
            </Toolbar>
        </AppBar> 
    
        <Drawer variant="permanent" className='drawer' >
            <List  className='drawer-list'>
                {
                    menu?.map(item=>(
                        <ListItem button key={item.label}>
                            <ListItemIcon   onClick={item.onClick}>
                            <Icon
                                color={item.iconColor}
                                className={clsx(item.icon, "icon")}
                            />
                            </ListItemIcon>
                            <ListItemText 
                                style={{marginLeft:"-20px"}} 
                                onClick={item.onClick}
                            >
                            <Typography  >{item.label}</Typography>
                            </ListItemText > 
                        </ListItem>
                        ))
                }
            </List>
            <Typography className="sider-copy">by EmbedX.io 2023  v0.5</Typography>
        </Drawer>
        </Grid>
    </Fragment>
    );
}


export default Navbar;