import React from 'react'
import { Grid, 
	Typography, 
	Box, 
	Stepper, 
	Step,  
	StepLabel
} from '@mui/material';
import { Navbar } from '../../components';

export default function Flows() {

	const steps = [
		'Flujo',
		'Diseño',
		'Publicación',
	];

	return (
		<Grid className='main-container mt-3'>
		<Navbar />
		<Grid className="container-page">
			<Typography variant='h5' color="primary" ><b>Flujos</b></Typography>
			<Typography variant="caption">Los flujos representan los pasos a seguir por el cliente para  la contratación de tus seguros. </Typography>
		</Grid>
		<Box sx={{ width: '100%' }}>
			<Stepper activeStep={0} alternativeLabel>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
		</Box>
		</Grid>
	)
}
