import  React, { useEffect, useState } from "react";
import './CardCustomer.scss';
import {
	Tab, 
	Tabs,
	Button,
	AppBar,
	TextField,
	Dialog,
	Grid,
	IconButton,
	Icon,
	Typography,
	Card,
	Box,
	CardHeader,
	CardContent,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from "styled-components";
import { useDispatch } from 'react-redux';
import { 
	createUser,
	deleteUser, 
	updateUser
} from "../../redux/actions/user-actions";
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import PetsIcon from '@mui/icons-material/Pets';
import Face6Icon from '@mui/icons-material/Face6';
import { getSuscriptions } from "../../redux/actions/suscription-actions";
import { backend } from "../../utils";


const CardCustomer = ({item, openModal, setOpenModal, callback, notification, cli}) => {
	const [open, setOpen] = useState(openModal);
	const dispatch = useDispatch()
	const userCreate = async (user) => dispatch(createUser(user))
	const userDelete = async (id) => dispatch(deleteUser(id))
	const userUpdate = async (id, user) => dispatch(updateUser(id, user))
	const getUserSuscriptions = async (id) => dispatch(getSuscriptions(id))
	const [valueTab, setValueTab] = useState(0);
	const [suscriptions , setSuscription] = useState(null);


	useEffect(()=>{

	},[])


	const validationSchema = Yup.object({
				name: Yup.string().required('Requerido'),
				last_name: Yup.string().required('Requerido'),
				email: Yup.string().required('Requerido'),
				phone: Yup.string().required('Requerido'),
				RUT: Yup.string().required('Requerido'),
				serie: Yup.string().required('Requerido'),
    })

	const formik = useFormik({
		initialValues: {
				name: item !== null ? item.name:'',
				last_name: item !== null ? item.last_name:'',
				email: item !== null ? item.email:'',
				phone: item !== null ? item.phone: '',
				RUT: item !== null ? item.RUT: '',
				serie: item !== null ? item.seie: '',
				provision: item !== null ? item.provision:'' ,
				street: item!== null ? item.address.street:'',
				number: item!== null ? item.address.number:'',
				colony: item!== null ? item.address.colony:'',
				region: item!== null ? item.address.region:''
		},
		onSubmit:  async (values) => {
			if(item){
				await userUpdate(item._id,values)
				notification('Usuario actualizado correctamente!')
			}else{
				await userCreate(values)
				notification('Usuario creado correctamente !')
			}
			callback();
			handleClose();
		},
		validationSchema,
		validate: values => {
				
				}
		})


	const handleDelete = async() => {
		await userDelete(item._id);
		callback();
		notification('Usuario eliminado correctamente !')
		handleClose();
  };

	const handleClose = () => {
    setOpen(false);
		setOpenModal(false);
  };

	const handleChangeTab =async  (event, newValue) => {
		setValueTab(newValue);
		if(newValue === 2)
		{
			const response = await getUserSuscriptions(item?.id)
			setSuscription(response.data)
		}
	};

	const renderIcon=(type)=>{
		switch(type){
			case backend.product.accidentes:
				return (
							<IconButton>
									<Face6Icon style={{fontSize: '50px'}}/>
						</IconButton>
				)
				case backend.product.oncologico:
					return (
								<IconButton>
										<MonitorHeartIcon style={{fontSize: '50px'}}/>
							</IconButton>
					)
				case backend.product.mascotas:
						return (
									<IconButton>
											<PetsIcon style={{fontSize: '50px'}}/>
								</IconButton>
						)
			default:
				break; 
		}
	}

	function TabPanel(props) {
			const { children, value, index, ...other } = props;
			return (
			<Grid
					role="tabpanel"
					hidden={value !== index}
					id={`simple-tabpanel-${index}`}
					aria-labelledby={`simple-tab-${index}`}
					{...other}
			>
							{value === index && (
							<Box p={3} >
									<Typography  component="div">{children}</Typography>
							</Box>
							)}
			</Grid>
			);
		}

  return (
		<Dialog
		open={open}
		onClose={setOpenModal}
		aria-labelledby="alert-dialog-title"	
		aria-describedby="alert-dialog-description"
		align='center'
		maxWidth="lg"
	>
		<form onSubmit={formik.handleSubmit} style={{padding: '0px'}}>
    <Grid 	className="modal-container">
				<Grid container justify="flex-end" className="card-close">
					<Grid item xs={10}></Grid>
					<Grid item xs={2} align='right'>
						<CloseButton onClick={handleClose}>
									<CloseIcon className="fas fa-times" />
							</CloseButton>
					</Grid>
				</Grid>

				<Typography variant="h5" className="mb-2">Información de <b>{item.name}</b></Typography>

				{/* Tabs de la informacion del Proveedor */}
				<AppBar position="static">
        <Tabs 
						style={{backgroundColor:"rgb(258,236,225)"}} 
						value={valueTab} onChange={handleChangeTab} 
						aria-label="simple tabs example"
					>
            <Tab label="Datos Personales"  />
            <Tab label="Dirección"  />
            <Tab label="Suscripción"   />
        </Tabs>
        </AppBar>

				{/* Datos personales */}
				<TabPanel value={valueTab} index={0}>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<TextField
								label="Nombre"
								id="outlined-size-small"
								name='name'
								focused
								fullWidth
								className="mt-1"
								value={formik.values.name} 
								error={formik.touched.name && formik.errors.name ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
								InputProps={{
									readOnly: true,
								}}
								/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label="Apellido"
								id="outlined-size-small"
								name='last_name'
								fullWidth
								className="mt-1"
								value={formik.values.last_name} 
								error={formik.touched.last_name && formik.errors.last_name ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.last_name && formik.errors.last_name ? formik.errors.last_name : ''}
								InputProps={{
									readOnly: true,
								}}
								/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Email"
								id="outlined-size-small"
								defaultValue={item?.email}
								name='email'
								fullWidth
								value={formik.values.email} 
								error={formik.touched.email && formik.errors.email ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
								InputProps={{
									readOnly: true,
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label="Telefono"
								id="outlined-size-small"
								defaultValue={item?.phone}
								name='phone'
								fullWidth
								value={formik.values.phone} 
								error={formik.touched.phone && formik.errors.phone ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.phone && formik.errors.phone ? formik.errors.phone : ''}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label="RUT"
								id="outlined-size-small"
								defaultValue={item?.RUT}
								name='RUT'
								fullWidth
								value={formik.values.RUT} 
								error={formik.touched.RUT && formik.errors.RUT ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.RUT && formik.errors.RUT ? formik.errors.RUT : ''}
								InputProps={{
									readOnly: true,
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label="Serie"
								id="outlined-size-small-serie"
								defaultValue={item?.serie}
								name='serie'
								fullWidth
								value={formik.values.serie} 
								error={formik.touched.serie && formik.errors.serie ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.serie && formik.errors.serie ? formik.errors.serie : ''}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label="Provisión"
								id="outlined-size-small-provision"
								defaultValue={item?.provision}
								name='provision'
								fullWidth
								value={formik.values.provision} 
								error={formik.touched.provision && formik.errors.provision ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.provision && formik.errors.provision ? formik.errors.provision : ''}
								InputProps={{
									readOnly: true,
								}}
							/>
						</Grid>
						<Grid item xs={6}>
						</Grid>
					</Grid>
				</TabPanel>

				{/* Direccion */}
				<TabPanel value={valueTab} index={1}>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<TextField
								label="Calle"
								id="outlined-size-small"
								name='street'
								fullWidth
								className="mt-1"
								value={formik.values.street} 
								error={formik.touched.street && formik.errors.street ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.street && formik.errors.street ? formik.errors.street : ''}
								InputProps={{
									readOnly: true,
								}}
								/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label="Número"
								id="outlined-size-small"
								name='number'
								fullWidth
								className="mt-1"
								value={formik.values.number} 
								error={formik.touched.number && formik.errors.number ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.number && formik.errors.number ? formik.errors.number : ''}
								InputProps={{
									readOnly: true,
								}}
								/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label="Comuna"
								id="outlined-size-small"
								defaultValue={item?.colony}
								name='colony'
								fullWidth
								value={formik.values.colony} 
								error={formik.touched.colony && formik.errors.colony ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.colony && formik.errors.colony ? formik.errors.colony : ''}
								InputProps={{
									readOnly: true,
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label="Región"
								id="outlined-size-small"
								defaultValue={item?.region}
								name='phone'
								fullWidth
								value={formik.values.region} 
								error={formik.touched.region && formik.errors.region ? true : false} 
								onChange={formik.handleChange} 
								onBlur={formik.handleBlur}
								helperText={formik.touched.region && formik.errors.region ? formik.errors.region : ''}
							/>
						</Grid>
					</Grid>
				</TabPanel>

				{/* Suscripciones */}

				{
					suscriptions?.map((sus)=>(
						<TabPanel value ={valueTab} index={2}>
							<Grid container spacing={3}>
							<Grid item xs={4}>
							<Card fullWidth className="hover-card">
									<CardHeader
										avatar={ renderIcon(sus.type)}
										title={sus.type}
									/>
									<CardContent>
										<Typography variant="body2" color="textSecondary"	>
											Póliza: <b>{sus.policy_number}</b>
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							</Grid>
						</TabPanel>
					))
				}
    </Grid>
		</form>
		<Grid container spacing={2} className="mb-1">
				{
						item && (
							<Grid xs={6} className="mt-1">
									<Button 
									disabled={true}
									variant="contained" 
									color="secondary"
									onClick={handleDelete}
									>
										ELIMINAR
								</Button>
							</Grid>
						)
					}
					<Grid item xs={6}>
									<Button 
									disabled={true}
									variant="contained" 
									color="primary" 
									type="submit"
									>
										GUARDAR
								</Button>
					</Grid>
				</Grid>
		</Dialog>
  );
};

export default CardCustomer;
 
const CloseButton = styled(IconButton)`
    color: #FF0050 !important;
    width: 35px;
    height: 35px;
		margin: 5px;
`;

const CloseIcon = styled(Icon)`
    font-size: 1.3rem !important;
`;