import React, { useState } from 'react';
import {Paper} from '@mui/material';
import {
    PagingState,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { alpha, styled } from '@mui/material/styles';
import CardCustomer from '../CardCustomer/CardCustomer';


const classes = {
    tableStriped: `ticker-tableStriped`,
};

const StyledTable = styled(Table.Table)(({ theme }) => ({
    [`&.${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
        },
    },
    }));

const TableComponent = props => (
        <StyledTable
        {...props}
        className={classes.tableStriped}
        />
    );

function TableCustomer({data , notification}){

    const [openDetail , setOpenDetail]= useState(false);
    const [clickedCutomer, setClickedCustomer] = useState(null);
    const [columns] = useState([
        { name: 'name', title: 'Nombre' },
        { name: 'last_name', title: 'Apellido' },
        { name: 'email', title: 'Correo'},
        { name: 'phone', title: 'Telefono'},
        { name: 'serie', title: 'Serie'},
        { name: 'RUT', title: 'RUT'},
    ]);

    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(10);

    const TableRow = ({ row, ...restProps }) => (
        <Table.Row
            {...restProps}
                onDoubleClick={()=>{
                    setOpenDetail(true)
                    handleItemClick(row)
                }}
        />
    ); 

    const handleItemClick=(row)=>{
        setClickedCustomer(row)
    }

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={data} columns={columns}>
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                />
                <Table
                    tableComponent={TableComponent} 
                    rowComponent={TableRow}
                />
                <TableHeaderRow />
                <PagingPanel />
            </Grid>
            {
            openDetail && (
            <CardCustomer
                item={clickedCutomer}
                openModal={openDetail}
                setOpenModal= {setOpenDetail}
                notification={notification}
            />)
			}
        </Paper>
    );
}

export default TableCustomer;