import React, { useState } from 'react';
import { AgChartsReact } from 'ag-charts-react';

const data = [
    { asset: 'Accidentes', amount: 60000 },
    { asset: 'Oncologico', amount: 40000 },
    { asset: 'Mascotas', amount: 20000 },
];

const MetricChartPie = () => {
    const [options, ] = useState({
        data: data,
        title: {
            text: 'Distribución por producto',
        },
        series: [
            {
                type: 'pie',
                angleKey: 'amount',
                calloutLabelKey: 'asset',
                sectorLabelKey: 'amount',
                sectorLabel: {
                    color: 'white',
                    fontWeight: 'bold',
                    formatter: ({ value }) => `$${(value / 1000).toFixed(0)}K`,
                },
            },
        ],
    });

    return <AgChartsReact options={options} />;
};

export default MetricChartPie;