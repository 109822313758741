/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import { 
	Typography , 
	Grid, 
	Tabs, 
	Tab, 
	Button, 
	MenuItem, 
	TextField, 
	Checkbox,
	Menu
} from "@mui/material";
import  Navbar  from '../../components/Navbar/Navbar';
import './Suscription.scss'
import { backend } from "../../utils";
import Face6Icon from '@mui/icons-material/Face6';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import PetsIcon from '@mui/icons-material/Pets';
import { getAllSuscriptions } from "../../redux/actions/suscription-actions";
import { useDispatch } from "react-redux";
import { TableSuscription } from "../../components";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled, alpha } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const top100Films = [
  'Partner 1' ,
   'Partner 2' ,
];

const Suscription=()=>{

	const [value, setValue] = useState(backend.product.accidentes);
	const dispatch = useDispatch();
	const getSuscriptions = async () => dispatch(getAllSuscriptions())
	const [dataAccidentes , setDataAccidentes] = useState(null);
  const [dataOncologico , setDataOncologico] = useState(null);
	const [dataMascotas , setDataMascotas] = useState(null);
	const [dataPartners , setDataPartners] = useState(null);
	const [dataSuscriptions , setDataSuscriptions] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [indexView, setIndexView] = useState(1);
	const open = Boolean(anchorEl);


	useEffect(()=>{
		loadData()
	},[])

	const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

	const handleClose = () => {
    setAnchorEl(null);
  };

	const loadData =async()=>{
		const res = await getSuscriptions()
		setDataSuscriptions(res.data);
		setDataPartners(res.data);
		setDataAccidentes(res.data.filter((product)=>product.type === backend.product.accidentes))
		setDataOncologico(res.data.filter((product)=>product.type === backend.product.oncologico))
		setDataMascotas(res.data.filter((product)=>product.type === backend.product.mascotas))
	}

	const handleChange = (event, newValue) => {
    setValue(newValue);
  };

	const onClickOption =(index)=>{
		setIndexView(index)
		setAnchorEl(null);
	}

	const renderTabsContent = (value)=>{
			switch(value){
				case backend.product.accidentes:
					return(
							<Grid className="mt-2">
								{
									dataAccidentes && (
										<TableSuscription
											data = {dataAccidentes}
										/>
									)
								}
						</Grid>
					)
				case backend.product.oncologico:
					return(
						<Grid className="mt-2">
								{
									dataOncologico && (
										<TableSuscription
											data = {dataOncologico}
										/>
									)
								}
						</Grid>
					)
				case backend.product.mascotas:
					return(
						<Grid className="mt-2">
								{
									dataMascotas && (
										<TableSuscription
											data = {dataMascotas}
										/>
									)
								}
						</Grid>
					)
				default:
					break;
		 }
	}



	const StyledMenu = styled((props) => (
		<Menu
			elevation={0}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			{...props}
		/>
	))(({ theme }) => ({
		'& .MuiPaper-root': {
			borderRadius: 6,
			marginTop: theme.spacing(1),
			minWidth: 180,
			color:
				theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
			boxShadow:
				'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
			'& .MuiMenu-list': {
				padding: '4px 0',
			},
			'& .MuiMenuItem-root': {
				'& .MuiSvgIcon-root': {
					fontSize: 18,
					color: theme.palette.text.secondary,
					marginRight: theme.spacing(1.5),
				},
				'&:active': {
					backgroundColor: alpha(
						theme.palette.primary.main,
						theme.palette.action.selectedOpacity,
					),
				},
			},
		},
	}));

	return(
		<Grid className='main-container mt-3'>
			<Navbar />
			<Grid className="container-page">
						<Typography variant='h5' color="primary" ><b>Suscripciones</b></Typography>
						<Typography variant="caption">Da click en VISTA para filtrar información por producto o por partner. </Typography>
						<Grid item align='right'>
									<Button
									id="demo-customized-button"
									aria-controls={open ? 'demo-customized-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={open ? 'true' : undefined}
									variant="contained"
									disableElevation
									onClick={handleClick}
									endIcon={<KeyboardArrowDownIcon />}
									color='secondary'
								>
									Vista
								</Button>
								<StyledMenu
									id="demo-customized-menu"
									MenuListProps={{
										'aria-labelledby': 'demo-customized-button',
									}}
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
								>
											<MenuItem onClick={()=>{onClickOption(1)}} disableRipple>
													<PetsIcon  size="large" fontSize="inherit"/>
													Por producto
												</MenuItem>
												<MenuItem onClick={()=>{onClickOption(2)}} disableRipple>
													<AccountCircleIcon size="large" fontSize="inherit" />
													Por partner
												</MenuItem>
								</StyledMenu>
						</Grid>
						{
							indexView === 1 ? (
								<Grid> 
										<Tabs
											value={value}
											onChange={handleChange}
											textColor="secondary"
											indicatorColor="primary"
										>
								<Tab 
									value={backend.product.accidentes}
									label=
									{
										<div  className="tab-container">
											<Face6Icon className="tab-icon"/> 
											<span className="tab-tex">ACCIDENTES P.</span>
										</div>
									}
									className="tab-tex"
								/>
								<Tab 
									value={backend.product.oncologico}
									label={
										<div  className="tab-container">
											<MonitorHeartIcon className="tab-icon"/> 
											<span className="tab-tex">ONCOLOGICO</span>
										</div>
									}
									className="tab-tex"
									/>
								<Tab 
									value={backend.product.mascotas}
									label={
										<div  className="tab-container">
											<PetsIcon className="tab-icon"/> 
											<span className="tab-tex">MASCOTAS</span>
										</div>
									}
									className="tab-tex"
									/>
									</Tabs>
										{renderTabsContent(value)}
								</Grid>
							
							):(
								<Grid>
											<Autocomplete
													multiple
													id="checkboxes-tags-demo"
													options={top100Films}
													disableCloseOnSelect
													getOptionLabel={(option) => option}
													renderOption={(props, option, { selected }) => (
														<li {...props}>
															<Checkbox
																icon={icon}
																checkedIcon={checkedIcon}
																style={{ marginRight: 8 }}
																checked={selected}
															/>
															{option}
														</li>
													)}
													style={{ width: 500 }}
													renderInput={(params) => (
														<TextField {...params} label="Seleccionar partner"  />
													)}
													onChange={(event, value) => {
														if(value.length === 0){
															setDataPartners(dataSuscriptions)
														}else{
															setDataPartners(dataSuscriptions.filter( partner => value.includes(partner.channel)))
														}
													}} 
												/>
													<Grid className="mt-2">
															<TableSuscription
															data = {dataPartners}
														/>
													</Grid>
								</Grid>
							)
						}
				</Grid>
		</Grid>
	)
}

export default Suscription;