import React, { useState } from 'react';
import { AgChartsReact } from 'ag-charts-react';

const MetricChartSales = () => {

  const [chartOptions] = useState({
    title: { text: 'Venta acumulado' },
    subtitle: { text: 'ultimos 6 meses ' },
    data: [
        { month: 'Jul', valor: 100, ventas: 100 },
        { month: 'Ago', valor: 150, ventas: 150 },
        { month: 'Sep', valor: 350, ventas: 350 },
        { month: 'Oct', valor: 400, ventas: 400 },
        { month: 'Nov', valor: 280, ventas: 280 },
        { month: 'Dic', valor: 230, ventas: 230 },
    ],
    series: [
        { type: 'bar', xKey: 'month', yKey: 'ventas' },
        { type: 'line', xKey: 'month', yKey: 'valor' }
      ],
  });

  return (
    <AgChartsReact options={chartOptions} />
  );
}

export default MetricChartSales;